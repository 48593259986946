@import "../../commonstyles/variables.scss";
@import "../../commonstyles/styleGuide.scss";

.section-heading {
  text-align: center;
  @extend .text-extra-large;
  @extend .bolder;
  color: $brandColor;
  padding: 40px;



  .heading {
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 50px;
      background-color: lighten($brandColor, 75%);
      left: -70px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 50px;
      background-color: lighten($brandColor, 75%);
      right: -70px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

