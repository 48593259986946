.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.j-c {
    justify-content: center;
}
.a-c {
    align-items: center;
}
.a-fs {
    align-items: flex-start;
}
.a-b {
    align-items: baseline;
}
.flex-1 {
    flex: 1 0 0;
}
.flex-2 {
    flex: 2 0 0;
}
.flex-2-5 {
    flex: 2.5 0 0;
}
.j-sb {
    justify-content: space-between;
}
.j-fe {
    justify-content: flex-end;
}
