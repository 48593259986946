@import "src/commonstyles/styleGuide";
@import "src/commonstyles/stylesConfiguration";


.homePage {
  .description-wrapper {
    min-height: calc(100vh - 66px);
    background: url("../../Img/home.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .description {
    font-size: 1.40rem;
    max-width: 600px;
    margin-left: 50px;
    padding-top: 50px;
    line-height: 1.8;
  }

  .sideGutter {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sticky {
    position: sticky;
    top: 1px;
    z-index: 1;
    background: $documentColor;
  }
}

.clients-section {
  min-height: 500px
}


@include media-breakpoint-down(md) {
  .description {
    padding: 40px 0 !important;
  }
}
@include media-breakpoint-up(md) {
  .description {
    width: 500px;
  }
}

