@import "../../commonstyles/variables.scss";

.team {
  padding: 60px 20px;
}

.list-group-details {
  display: none !important;
}

.list-title:not(:hover) {
  box-shadow: 0 2px 10px -1px rgba(247,242,242,0.23);
}

.team-member:hover {
  z-index: 1;
  .list-title{
    + .list-group-details {
      display: block !important;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      box-shadow: -1px 6px 20px 12px rgba(247, 242, 242, 0.23), -1px 6px 16px 5px rgba(0, 0, 0, 0.23);
      z-index: 100;
      background-color: white;
      border-radius: 3.5px;
    }
  }
}

.team-member {
  position: relative;
}

.team-member {
  flex-basis: 200px !important;
}

.team-image {
  height: 400px;
}

.team-page {
  height: 100%;
  background: url('../../Img/team.jpg') no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) {

  .team-member {
    flex-basis: 22% !important;
  }
}
