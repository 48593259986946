@import "../../commonstyles/variables.scss";

.clients {
  .img {
    max-height: 300px;
  }
  .carousel.slide {
    height: 300px;
    display: flex;
    align-items: center;
  }
}
