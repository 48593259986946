@import '../../commonstyles/variables.scss';

.mainContainer {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    footer {
        font-size: 0.85rem;
        background-color: $brandColor;
        color: white;
        padding: 10px 20px 0 20px;
        display: flex;
        flex-direction: row;
        .socialIcons {
            i {
                padding: 8px;
                &:hover {
                    color: $text-primary;
                    cursor: pointer;
                }
            }
        }
    }
    .brandName {
        font-size: 25px;
        font-weight: 600;
    }
    .brandWrapper {
        .brandName {

        }
        .title {
            font-size: 17px;
            font-style: italic;
        }
    }

    @media only screen and (max-width: 767px) {
        footer {
            display: flex;
            flex-direction: column-reverse;
            font-size: 0.7rem;
            text-align: center;
            .socialIcons i {
                padding: 20px;
            }
        }
    }
}
