@import './variables.scss';
@import './normalize.scss';
@import './flex.scss';
@import "./styleGuide.scss";

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #eee;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: $brandColor;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

.shine {
  background: #eee;
  background-image: linear-gradient(
                  to right,
                  #eee 0%,
                  #edeef1 20%,
                  #eee 40%,
                  #eee 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

#root, .mainContainer {
  //height: 100%;
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 18px;
  }
}

.brandColor {
  color: $brandColor;
}

button {
  padding: 8px 12px;
  border: none;
  border-radius: 2px;

  &.primary {
    background-color: $brandColor;
    color: white;
    border: 1px solid transparent;
    transition: all ease-in 200ms;
    &:hover {
      background-color: white;
      border: 1px solid $brandColor;
      color: $brandColor;
    }
  }
  &.default {
    background-color: white;
    color: $brandColor;
    border: 1px solid $brandColor;
    transition: all ease-in 200ms;
    &:hover {
      background-color: $brandColor;
      border: 1px solid transparent;
      color: white;
    }
  }
  &.tertiary {
    background-color: $error;
    color: white;
    border: 1px solid transparent;
    transition: all ease-in 200ms;
    &:hover {
      background-color: white;
      border: 1px solid $error;
      color: $error;
    }
  }
}
