@import "../../commonstyles/variables.scss";

.navigation {
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
