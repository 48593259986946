$primary: #333;
$prime: #606060;
$secondary: #8a8a8a;

$error: #f11707;

$text-primary: $primary;
$text-secondary: $secondary;

$brandColor: #47143D;

$documentColor: white;
